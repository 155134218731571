export const environment = {
  production: true,

  version: '2024.7.0',
  versionHash: '8dc7464c53d37fcb563339f2ec49b10a1fe5554e',

  api: {
    basePath: 'https://api.dev.sugardaddy-vananaz-intra.com',
  },

  stream: {
    basePath: 'wss://api.dev.sugardaddy-vananaz-intra.com',
  },

  cms: {
    basePath: 'https://help.dev.sugardaddy-vananaz-intra.com',
  },

  site: {
    noindex: 'yes',
  },

  payment: {
    usePaymentServiceMock: false,
    paidy: {
      apiKey: 'pk_test_edplhs06uv2ahi2l6v4odru8sh',
    }
  },

  amplify: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_y4LOFss7b',
      userPoolWebClientId: '1ilc22jga08q5d4f3um9t38e0f',
    },
  },

  recatpcha: {
    siteKey: '6LcB4_8pAAAAADXOLeqptze3rGG24Y72RZH2rd4J',
  },

  analysis: {
    gtagId: 'G-LH216B2FW9',
    gtagId2: 'GTM-K6KF47NZ',
    gtm_auth: 'ma-SaVDIMCaSOZAep3xE0A',
    gtm_preview: 'env-4',
    gtm_resource_path: '',
    pixelId: '1976549862601304',
    uwtId: 'o6019',
  },
};

export const ENV_MODULES: any[] = [];

export const ENV_PROVIDERS: any[] = [];
